import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'src/components/atoms/Section/Section';
import { WizardBody } from 'src/components/DUP/atoms/WizardBody';
import { Success } from 'src/components/DUP/molecules/Success';
import { WizardDocumentUploadSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import WizardFooter from 'src/components/DUP/molecules/WizardFooter';
import WizardFormApplicant from 'src/components/DUP/molecules/WizardFormApplicant';
import { WizardHeader } from 'src/components/DUP/molecules/WizardHeader';
import WizardProofOfIncome from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { WizardSubmit } from 'src/components/DUP/molecules/WizardSubmit';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import { useResource } from 'src/hooks/useResource';
import { useWizardSteps } from 'src/hooks/useWizardSteps';
import { WizardContainer } from 'src/pages/DUPPage/styles';
import { Proof, UnauthenticateSessionProofType } from 'src/types/api';

// Use this type to define the props for each of the sub-components in the DUP wizard
export interface DupScreenStepProps {
  onFinished: () => void;
  goBack: () => void;
  activeStepIdx: number;
  setActiveStepIdx: (idx: number) => void;
}

export const SNAX_APP_TITLE = 'Snappt AX';

/**
 * This component is the main entry point for the DUP wizard.
 * It will display the header, footer, and the various steps in the wizard.
 */
export const DUPWizard = () => {
  useEffect(() => {
    document.title = SNAX_APP_TITLE;
    if (window.heap) {
      window.heap.trackPageview({ title: SNAX_APP_TITLE });
    }
  }, []);

  const { t } = useTranslation();

  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);

  if ('error' in proofs) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup.submission.error.title')}
          message={t('dup.submission.error.message')}
        />
      </Section>
    );
  }

  const handleNext = () => {
    setActiveStepIdx((prevStep) => prevStep + 1);
  };

  const stepComponentProps = {
    setActiveStepIdx,
    onFinished: handleNext,
    goBack: () => setActiveStepIdx((prevStep) => prevStep - 1)
  };

  const wizardComponents = [
    <WizardFormApplicant key={0} activeStepIdx={0} {...stepComponentProps} />,
    <WizardProofOfIncome
      key={1}
      activeStepIdx={1}
      proofType={UnauthenticateSessionProofType.Paystub}
      proofs={proofs}
      refresh={refresh}
      {...stepComponentProps}
    />,
    <WizardDocumentUploadSection
      key={2}
      activeStepIdx={2}
      proofType={UnauthenticateSessionProofType.BankStatement}
      proofs={proofs}
      refresh={refresh}
      {...stepComponentProps}
    />,
    <WizardSubmit key={3} activeStepIdx={3} proofs={proofs} {...stepComponentProps} />,
    <Success key={4} />
  ];

  return (
    <WizardContent activeStepIdx={activeStepIdx} {...stepComponentProps}>
      {wizardComponents[activeStepIdx]}
    </WizardContent>
  );
};

const WizardContent = ({
  activeStepIdx,
  setActiveStepIdx,
  children
}: DupScreenStepProps & { children: JSX.Element }) => {
  const steps = useWizardSteps();
  const step = steps[activeStepIdx];
  const stepInfoAttributes = step ? step.stepInfoAttributes : {};

  return (
    <WizardContainer {...stepInfoAttributes}>
      <WizardHeader activeStepIdx={activeStepIdx} goToStepIdx={setActiveStepIdx} />
      <WizardBody activeStep={activeStepIdx}>
        {children}
        {activeStepIdx !== 4 && <WizardFooter hideOn="desktop" />}
      </WizardBody>
      {activeStepIdx !== 4 && <WizardFooter hideOn="mobile" />}
    </WizardContainer>
  );
};
