import { pick, snakeCase } from 'lodash';
import QueryString from 'qs';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import InputText, { InputError, InputTextType } from 'src/components/atoms/InputText/InputText';
import Row from 'src/components/atoms/Row/Row';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { useApplication } from 'src/context/ApplicationContext';
import { letterMatchingRegex, onlyEmailRegex } from 'src/helpers/formRejex';
import useInformation from 'src/hooks/useInformation';
import {
  DupApplicationType,
  SessionApplication,
  SessionApplicationUpsertBody
} from 'src/types/api';

export type FormData = ReturnType<typeof useFormApplicant>;

const FormKeys: (keyof SessionApplication)[] = [
  'firstName',
  'middleInitial',
  'lastName',
  'phone',
  'email',
  'notificationEmail',
  'unit'
];

export const useFormApplicant = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { type, application, onUpdateApplication, isWizard, property } = useApplication();
  const information = useInformation();
  const inputProps = isWizard
    ? {
        includeRequiredIndicator: true,
        alwaysShowLabel: true,
        labelStyle: { top: -24, left: -4 },
        style: { width: '100%', marginTop: 12, borderRadius: 8 }
      }
    : undefined;

  const [inputErrors, setInputErrors] = useState<InputError[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const defaultFormValues = {
    ...pick(application, FormKeys),
    ...initialValuesFromQueryString(search)
  };
  const form = useForm<SessionApplicationUpsertBody>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultFormValues
  });
  const requiredFields = useMemo(() => {
    const required = ['firstName', 'lastName', 'email'];
    if (property?.phoneIsRequired) {
      required.push('phone');
    }
    if (type === DupApplicationType.LEASING_TEAM) {
      required.push('notificationEmail');
    }
    return required as (keyof SessionApplication)[];
  }, [property, type]);

  const {
    getValues,
    setValue,
    formState: { isValid }
  } = form;

  const onSave = async () => {
    if (isSaving) return false;

    setIsSaving(true);
    const application = getValues();
    if (application.phone) {
      application.phone = application.phone.replace(/\D/g, '');
    }

    try {
      await onUpdateApplication({ ...application });
      setInputErrors([]);
      setIsSaving(false);
      return true;
    } catch (error) {
      setInputErrors(inputErrorsFromResponse(error as { message: string }));
      setIsSaving(false);
      return false;
    }
  };

  const onChanging = async (changes: { field: string; value: string | boolean }) => {
    const { field, value } = changes;
    const hasAllRequiredFields = requiredFields.every((f) => f === field || !!getValues(f));
    setValue(field as keyof SessionApplicationUpsertBody, value ?? '', {
      shouldValidate: isValid || hasAllRequiredFields
    });
  };

  const PropertyNameInput = (
    <InputText
      label={t('dup.form.label.propertyName')}
      name="propertyName"
      type={InputTextType.text}
      isReadonly
      defaultValue={property?.name}
      errors={[]}
    />
  );

  const UnitInput = property?.unitIsRequired && (
    <InputText
      {...inputProps}
      label={t('dup.form.label.unitNum')}
      placeholder={isWizard ? t('dup.form.placeholder.unitNum') : undefined}
      name="unit"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) => {
        if (String(value).length <= 8) {
          onChanging({
            field: 'unit',
            value
          });
        }
      }}
      config={{
        min: {
          value: 1,
          message: t('validation.shouldBeCountOrMore', { count: 1 })
        },
        maxLength: {
          value: 8,
          message: t('validation.shouldBeLessThanCountChar', { count: 8 })
        }
      }}
    />
  );

  const FirstNameInput = (
    <InputText
      {...inputProps}
      label={t('dup.form.label.firstName')}
      name="firstName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'firstName',
          value
        })
      }
      config={{
        required: t('validation.completeThisField'),
        maxLength: {
          value: 25,
          message: t('validation.countCharMax', { count: 25 })
        },
        validate: {
          noSpecialCharacters: (value) =>
            letterMatchingRegex.test(value) || t('validation.shouldNotContainNumOrSym')
        }
      }}
      required={true}
    />
  );

  const MiddleInitialInput = (
    <InputText
      {...inputProps}
      label={t('dup.form.label.middleInitial')}
      name="middleInitial"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) => {
        onChanging({
          field: 'middleInitial',
          value
        });
      }}
      config={{
        maxLength: {
          value: 1,
          message: t('validation.shouldOnlyBeOneChar')
        },
        validate: {
          noSpecialCharacters: (value) =>
            value
              ? letterMatchingRegex.test(value) || t('validation.shouldNotContainNumOrSym')
              : undefined
        },
        setValueAs: (value) => (typeof value === 'string' ? value.toUpperCase() : value)
      }}
    />
  );

  const LastNameInput = (
    <InputText
      {...inputProps}
      label={t('dup.form.label.lastName')}
      name="lastName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'lastName',
          value
        })
      }
      config={{
        required: t('validation.completeThisField'),
        maxLength: {
          value: 50,
          message: t('validation.countCharMax', { count: 50 })
        },
        validate: {
          noSpecialCharacters: (value) =>
            letterMatchingRegex.test(value) || t('validation.shouldNotContainNumOrSym')
        }
      }}
      required={true}
    />
  );

  const EmailInput = (
    <InputText
      {...inputProps}
      label={information.emailLabel}
      placeholder={isWizard ? t('dup.form.placeholder.email') : undefined}
      name="email"
      type={InputTextType.email}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'email',
          value
        })
      }
      config={{
        required: t('validation.completeThisField'),
        validate: {
          email: (value) => onlyEmailRegex.test(value) || t('validation.email.shouldBeValid')
        }
      }}
      required={true}
    />
  );

  const PhoneInput = property?.phoneIsRequired && type !== DupApplicationType.AOA && (
    <InputText
      {...inputProps}
      label={t('dup.form.label.phone')}
      name="phone"
      placeholder={isWizard ? '(555) 000-0000' : undefined}
      type={InputTextType.tel}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'phone',
          value
        })
      }
      config={{
        required: t('validation.completeThisField')
      }}
      required={true}
    />
  );

  const NotificationEmailInput = type === DupApplicationType.LEASING_TEAM && (
    <ToolTip content={information.tooltipMsg} direction={ToolTipDirection.bottom_fit}>
      <InputText
        label={t('dup.form.label.emailNotifs')}
        name="notificationEmail"
        type={InputTextType.email}
        errors={inputErrors}
        onChange={({ value }) =>
          onChanging({
            field: 'notificationEmail',
            value
          })
        }
        required={true}
      />
    </ToolTip>
  );

  const HavePrevSubmitRow = property?.name && (
    <Row key={`key_HavePreviousSubmit_${!!application?.hasPreviouslySubmitted}`}>
      <Checkbox
        name="HavePreviousSubmit"
        value="havePreviousSubmit"
        showLabel={true}
        label={information.prevSubmission}
        isChecked={!!application?.hasPreviouslySubmitted}
        onClick={(e) =>
          onChanging({
            field: 'hasPreviouslySubmitted',
            value: e.isChecked
          })
        }
      />
    </Row>
  );

  return {
    form,
    saveDisabled: !isValid || isSaving,
    PropertyNameInput,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    NotificationEmailInput,
    HavePrevSubmitRow,
    onSave
  } as const;
};

function initialValuesFromQueryString(search: string) {
  const query = QueryString.parse(search.replace(/^\?/, ''));
  const values: { [key: string]: string | undefined } = {};
  for (const field of FormKeys) {
    for (const form of [
      field,
      snakeCase(field),
      `applicant.${field}`,
      `applicant.${snakeCase(field)}`
    ]) {
      if (query[form]) {
        values[field] = `${query[form]}`;
      }
    }
  }
  return values;
}

function inputErrorsFromResponse(error: { message: string }) {
  const splitErrorMessages = error.message.split(',');
  return splitErrorMessages.map((errorMessage) => {
    const errorMessageParsed = errorMessage.split(':');
    return {
      field: errorMessageParsed[0]?.trim(),
      message: errorMessageParsed[1]?.trim(),
      errorParam: errorMessageParsed[2]?.trim()
    };
  });
}
