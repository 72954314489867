import { Icon as Logo } from 'src/components/atoms/Icon';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { useApplication } from 'src/context/ApplicationContext';
import { Information } from 'src/hooks/useInformation';
import { Container, Content } from './styles';

export type HeaderProps = {
  information: Information;
};

const Header: React.FC<HeaderProps> = ({ information }: HeaderProps) => {
  const { type } = useApplication();

  return (
    <Container type={type}>
      <Content>
        <Logo icon="logo-dup" />
        <Text color={TextColor.initial} variant={TextVariant.h3} align={TextAlign.center} isBold>
          {information.title}
        </Text>
        <LanguageSelector />
      </Content>
    </Container>
  );
};

export default Header;
