import { useTranslation } from 'react-i18next';
import { useApplication } from 'src/context/ApplicationContext';
import { Container, Row, StyledSubText, StyledText } from './styles';
const PUBLIC_URL = process.env.PUBLIC_URL;

const Success = () => {
  const { property } = useApplication();
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <img src={`${PUBLIC_URL}/assets/images/success_squid.png`} alt="Squid Success" />
      </Row>

      <StyledText>{t('dup.wizard.success.congrats')}</StyledText>
      <StyledSubText>
        {t('dup.wizard.success.message', { property: property.name || 'The property' })}
      </StyledSubText>
    </Container>
  );
};

export default Success;
