import WizardConnectedPayrollSection from 'src/components/DUP/molecules/WizardConnectedPayrollSection/WizardConnectedPayrollSection';
import { WizardDocumentUploadSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import { useApplication } from 'src/context/ApplicationContext';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Proof, UnauthenticateSessionProofType } from 'src/types/api';

export type WizardProofOfIncomeProps = DupScreenStepProps & {
  proofType: UnauthenticateSessionProofType;
  proofs: Proof[];
  refresh?: () => Promise<Proof[]>;
};

export const WizardProofOfIncome = (props: WizardProofOfIncomeProps) => {
  const { connectedPayrollEnabled } = useApplication();

  return connectedPayrollEnabled ? (
    <WizardConnectedPayrollSection {...props} />
  ) : (
    <WizardDocumentUploadSection {...props} />
  );
};

export default WizardProofOfIncome;
