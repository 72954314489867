import { configVars } from 'src/helpers';
import { AxRoute } from 'src/hooks/useAxApi';
import * as language from 'src/i18n/en';
import { UnauthenticateSessionProofType } from 'src/types/api';

export const NAME = 'DUP';
export const PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL = `${configVars.applicant_experience_api_url}/${AxRoute.CONNECTED_PAYROLL}`;
export const APPLICANT_EXPERIENCE_API_URL = configVars.applicant_experience_api_url;
export const AOA_PROPERTY_SLUG = 'AOA';
export const HOW_TO_VIDEO_URL = 'https://vimeo.com/645372425';
export const ARGYLE_HOW_TO_VIDEO_URL = 'https://argyle-1.wistia.com/a/ubwz4x4htlxh9h6';

export const UNAUTHENTICATED_SESSION_TOKEN_KEY = 'unauthenticated_session_token';

export const URLS_SLUG = {
  leasing_team: {
    name: 'leasing_team',
    path: '/applyonbehalf/',
    link: '/application/applyonbehalf/'
  },
  applicant: { name: 'unauthenticated_user', path: '', link: '/application/apply/' }
};

export const DOCUMENT_TYPES = [
  {
    value: UnauthenticateSessionProofType.Paystub,
    label: language.default.dup.documentType.paystub
  },
  {
    value: UnauthenticateSessionProofType.BankStatement,
    label: language.default.dup.documentType.bankStatement
  }
] satisfies { value: UnauthenticateSessionProofType; label: string }[];
