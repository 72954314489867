import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DUP_APP_TITLE } from 'src/App';
import { Body } from 'src/components/DUP/atoms/Body';
import { Header } from 'src/components/DUP/atoms/Header';
import { Footer } from 'src/components/DUP/molecules/Footer';
import { FormApplicant } from 'src/components/DUP/molecules/FormApplicant';
import { UploadFileSection } from 'src/components/DUP/molecules/UploadFileSection';
import { UploadInstructions } from 'src/components/DUP/molecules/UploadInstructions';
import { Line } from 'src/components/atoms/Line';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import { useFormApplicant } from 'src/hooks/useFormApplicant';
import useInformation from 'src/hooks/useInformation';
import { useRequest, useResource } from 'src/hooks/useResource';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import { ApplicationSubmitResponse, Proof } from 'src/types/api';

export const DUPPage = () => {
  useEffect(() => {
    if (window.heap) {
      window.heap.trackPageview({ title: DUP_APP_TITLE });
    }
  }, []);

  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { t } = useTranslation();
  const { makeRequest } = useRequest();

  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);
  const information = useInformation();

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [proofIsProcessing, setProofIsProcessing] = React.useState(false);

  const formData = useFormApplicant();

  const onSubmit = async () => {
    const savedApplication = await formData.onSave();

    if (savedApplication) {
      const response = await makeRequest<ApplicationSubmitResponse>({
        path: `/session/submit`,
        method: 'POST'
      });
      if ('error' in response) {
        showSnackbar(VariantType.error, t('dup.submission.error.title'), SnackTypes.none);
      } else if ('applicantId' in response) {
        setIsSubmitted(true);
      }
    }
  };

  if (isSubmitted) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.success}
          title={t('dup.submission.success.title')}
          message={t('dup.submission.success.message')}
        />
      </Section>
    );
  }

  return (
    <div>
      <Header information={information} />
      <Body>
        <FormApplicant formData={formData} />
        <Line />
        <UploadInstructions instructions={information.uploadInstruction} />
        <UploadFileSection
          proofs={proofs}
          refresh={() => refresh()}
          setProofIsProcessing={setProofIsProcessing}
        />
        <Footer
          information={information}
          isSubmittable={!formData.saveDisabled && !!proofs.length}
          proofIsProcessing={proofIsProcessing}
          isProofsHasErrors={proofs.some((p) => !!p.jobs_error?.length)}
          onSubmit={onSubmit}
        />
      </Body>
    </div>
  );
};
