import { KeyPrefix, Namespace, ResourceKey } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { useApplication } from 'src/context/ApplicationContext';
import { UnauthenticateSessionProofType } from 'src/types/api';
import { Instructions, StyledUl } from './styles';

export const useWizardUploadInstructions = ({
  proofType
}: Pick<WizardProofOfIncomeProps, 'proofType'>) => {
  const { t } = useTranslation();
  const { property } = useApplication();
  const docAmt = property.supportedDoctypes?.[proofType.toLowerCase()] || 0;
  const docType = t(getDocTypeTranslationKey(proofType, docAmt), { count: docAmt });

  const instructions = [
    t(
      `dup.wizard.instructions.first.${proofType === UnauthenticateSessionProofType.Paystub ? 'paystub' : 'other'}`,
      { count: docAmt, docType }
    ),
    t('dup.wizard.instructions.second'),
    t('dup.wizard.instructions.third')
  ];

  const InstructionsEl = (
    <Instructions>
      <span>{t('dup.wizard.instructions.title')}:</span>
      <StyledUl paddingInlineStart="1rem">
        {instructions.map((instruction, index) => (
          <li key={`wiz-upload-instruction-${index}`}>
            <Trans>{instruction}</Trans>
          </li>
        ))}
      </StyledUl>
    </Instructions>
  );

  return { instructions, InstructionsEl };
};

const getDocTypeTranslationKey = (type: string, documentsAmount: number): ResourceKey => {
  const keyPrefix: KeyPrefix<Namespace> = 'dup.instructions';
  if (type === UnauthenticateSessionProofType.Paystub) {
    return `${keyPrefix}.paystub`;
  }
  if (type === UnauthenticateSessionProofType.BankStatement && Boolean(documentsAmount)) {
    return `${keyPrefix}.bankStatement`;
  }
  return `${keyPrefix}.additionalDocs`;
};
