/**
 * This header component is intended to be usd with the DUPWizard component.
 */
import { Trans } from 'react-i18next';
import { IconColor } from 'src/components/atoms/Icon';
import { TextColor } from 'src/components/atoms/Text';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { useApplication } from 'src/context/ApplicationContext';
import { useWizardSteps, WizardStepInfo } from 'src/hooks/useWizardSteps';
import {
  Container,
  H1,
  HeaderRow,
  ProgressContainer,
  PropertyName,
  Step,
  Subtitle
} from './styles';

interface ProgressProps {
  activeStepIdx: number;
  goToStepIdx: (idx: number) => void;
  steps: WizardStepInfo[];
}

export type WizardHeaderProps = Omit<ProgressProps, 'steps'>;

const WizardHeader = (props: WizardHeaderProps) => {
  const { property } = useApplication();
  const { activeStepIdx } = props;
  const steps = useWizardSteps();
  const { title, description } = steps[activeStepIdx] || steps[0];

  return (
    <Container>
      <HeaderRow>
        <PropertyName>{property.name}</PropertyName>
        <LanguageSelector
          iconColor={IconColor.gray900}
          textColor={TextColor.secondary}
          size="small"
        />
      </HeaderRow>
      {activeStepIdx < steps.length && (
        <>
          <WizardProgress {...props} />
          <H1>{title}</H1>
          <Subtitle>
            <Trans>{description}</Trans>
          </Subtitle>
        </>
      )}
    </Container>
  );
};

export const WizardProgress = ({ activeStepIdx, goToStepIdx }: WizardHeaderProps) => {
  const steps = useWizardSteps();

  return (
    <ProgressContainer>
      {steps.map(({ title, stepNumber }, idx) => {
        return (
          <Step
            key={stepNumber}
            isActive={activeStepIdx === idx}
            onClick={activeStepIdx ? () => goToStepIdx(idx) : undefined}
          >
            <HideOnMobile>{title}</HideOnMobile>
          </Step>
        );
      })}
    </ProgressContainer>
  );
};

export default WizardHeader;
