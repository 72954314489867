import Button from 'src/components/atoms/Button';
import Col from 'src/components/atoms/Row/Col';
import Text from 'src/components/atoms/Text';
import { ButtonsContainer } from 'src/components/DUP/molecules/WizardProofOfIncome/styles';
import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  max-width: 47rem;
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 1rem;
  }
`;

export const ReviewSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewSectionContent = styled.div`
  opacity: 0.8;
`;

export const SectionContainer = styled(Col)`
  margin-bottom: 2rem;
  margin-top: 1.5rem;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const SectionTitle = styled(Text)`
  ${({ theme }) => theme.redesignFont.small}
  font-family: ${({ theme }) => theme.redesignFont.bold.fontFamily};
`;

export const TermsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  gap: 0.3rem;
  align-items: center;

  & path {
    fill: ${({ theme }) => theme.redesignColor.gray[700]};
  }
`;

export const Terms = styled.div`
height: 24px;
  ${({ theme }) => theme.redesignFont.caption}

  & a {
    ${({ theme }) => theme.redesignFont.caption}
    font-family: ${({ theme }) => theme.redesignFont.bold.fontFamily};
    text-transform: lowercase;

    &:hover {
      color: ${({ theme }) => theme.redesignColor.snappt[700]};
  }
`;

export const EditButton = styled(Button)`
  height: 32px;
  border-radius: 48px;
  font-family: ${({ theme }) => theme.redesignFont.bold.fontFamily};
  line-height: 20px;
  padding: 18px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.redesignColor.snappt[500]};
  &:hover {
    color: ${({ theme }) => theme.redesignColor.snappt[600]};
  }
`;

export const DocumentRow = styled.div<{ error?: boolean }>`
  display: flex;
  line-height: 24px;
  gap: 0.4rem;

  ${({ error, theme }) =>
    error &&
    `

      & span:first-child {
        color: ${theme.redesignColor.red[800]};
    }

      & svg {
        path {
          stroke: ${theme.redesignColor.red[800]};
        }
      }
  `}

  & span {
    ${({ theme }) => theme.redesignFont.caption}
    color: ${({ theme }) => theme.redesignColor.gray[700]};
    max-width: 400px;
    @media ${({ theme }) => theme.device.mobile} {
      max-width: 300px;
    }
    &:first-child {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.32px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 400px;
      @media ${({ theme }) => theme.device.mobile} {
        max-width: 250px;
      }
    }
  }
`;

export const NoDocumentsContainer = styled.div`
  background: ${({ theme }) => theme.color.gray[100]};
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
`;

export const ActionContainer = styled(ButtonsContainer)`
  margin: 0;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => theme.redesignFont.small}
`;
