import styled from 'styled-components';

export const WIZARD_BODY_MAX_WIDTH = 512;

export const OuterContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: ${WIZARD_BODY_MAX_WIDTH}px;
  z-index: 1;
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 0;
  }
`;

export const InnerContainer = styled.div`
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
