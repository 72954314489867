import { StyledButton } from 'src/pages/DUPPage/styles';
import styled from 'styled-components/macro';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 1rem;

  /* inputs */
  > div > div {
    border-radius: 8px;
  }

  /* checkbox */
  & path {
    fill: ${({ theme }) => theme.redesignColor.gray[700]};
  }
`;

export const Button = styled(StyledButton)`
  width: 100%;
  margin-top: 1.5rem;
`;
