import UploadFiles from 'src/components/DUP/molecules/UploadFiles/UploadFiles';
import { useApplication } from 'src/context/ApplicationContext';
import { Proof } from 'src/types/api';
import { Container, Content } from './styles';

export type UploadFilesProps = {
  proofs: Proof[];
  refresh: () => Promise<Proof[]>;
  setProofIsProcessing: (isProcessing: boolean) => void;
};
const UploadFileSection = ({ proofs, refresh, setProofIsProcessing }: UploadFilesProps) => {
  const { type } = useApplication();

  return (
    <Container type={type}>
      <Content>
        <UploadFiles
          type={type}
          refresh={refresh}
          proofs={proofs}
          setProofIsProcessing={setProofIsProcessing}
        />
      </Content>
    </Container>
  );
};

export default UploadFileSection;
