import { KeyPrefix, Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useWizardUploadInstructions } from 'src/components/DUP/molecules/WizardUpload/useWizardUploadInstructions';
import { useApplication } from 'src/context/ApplicationContext';
import { UnauthenticateSessionProofType } from 'src/types/api';

type WizardStepNumber = 1 | 2 | 3 | 4;

export interface StepInfoAttributes {
  'data-step-number': string;
  'data-step-title': string;
}

export interface WizardStepInfo {
  title: string;
  description: string;
  stepNumber: WizardStepNumber;
  stepInfoAttributes: StepInfoAttributes;
}

export function useWizardSteps() {
  const { t } = useTranslation();
  const { connectedPayrollEnabled } = useApplication();
  const { instructions } = useWizardUploadInstructions({
    // These instructions are for step 3, so it should match the step 3 proof type
    proofType: UnauthenticateSessionProofType.BankStatement
  });

  const keyPrefix: KeyPrefix<Namespace> = 'dup.wizard.step';

  const steps: WizardStepInfo[] = ([1, 2, 3, 4] as WizardStepNumber[]).map((stepNumber) => {
    let description = t(`${keyPrefix}.${stepNumber}.description`);
    if (stepNumber === 2 && connectedPayrollEnabled) {
      description = t(`${keyPrefix}.2.descriptionConnectedPayroll`);
    } else if (stepNumber === 3) {
      description = instructions[0] + description;
    }

    return {
      stepNumber,
      title: t(`${keyPrefix}.${stepNumber}.title`),
      description,
      stepInfoAttributes: {
        'data-step-number': `${stepNumber}`,
        'data-step-title': t(`${keyPrefix}.${stepNumber}.title`, { lng: 'en' })
      }
    };
  });

  return steps;
}
