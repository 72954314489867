import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../Spinner';
import { ButtonComponent } from './styles';

export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset'
}

export enum ButtonVariant {
  contained = 'contained',
  outline = 'outline',
  ghost = 'ghost'
}

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  cancel = 'cancel',
  disabled = 'disabled'
}

export enum ButtonSize {
  big = 'big',
  normal = 'normal',
  medium = 'medium'
}

export enum ButtonDisabledStyle {
  white = 'white',
  gray = 'gray',
  transparent = 'transparent',
  whiteWithBorder = 'whiteWithBorder'
}

export type ButtonProps = {
  name: string;
  type?: ButtonType;
  children: React.ReactNode;
  color?: ButtonColor;
  variant: ButtonVariant;
  size?: ButtonSize;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  dataId?: string;
  disabledStyle?: ButtonDisabledStyle;
  className?: string;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    className,
    onClick,
    name,
    type,
    variant,
    size,
    isDisabled,
    children,
    color,
    isLoading,
    label,
    dataId,
    disabledStyle,
    ...rest
  } = props;

  return (
    <ButtonComponent
      className={className}
      name={name}
      type={type}
      id={name}
      variant={variant}
      size={size}
      disabled={isDisabled}
      color={color}
      aria-disabled={isDisabled}
      aria-label={label}
      aria-pressed={true}
      data-id={dataId || label || name}
      onClick={onClick}
      disabledStyle={disabledStyle}
      {...rest}
    >
      {children}
      {isLoading && <Spinner size="normal" color="white" />}
    </ButtonComponent>
  );
};

export const ButtonPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf<ButtonType>(Object.values(ButtonType)),
  variant: PropTypes.oneOf<ButtonVariant>(Object.values(ButtonVariant)).isRequired,
  color: PropTypes.oneOf<ButtonColor>(Object.values(ButtonColor)).isRequired,
  size: PropTypes.oneOf<ButtonSize>(Object.values(ButtonSize)).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export const ButtonDefaultProps = {
  name: 'Button',
  className: '',
  variant: ButtonVariant.contained,
  color: ButtonColor.primary,
  size: ButtonSize.normal,
  isDisabled: false,
  isLoading: false,
  label: 'Button'
};

Button.propTypes = ButtonPropTypes;
Button.defaultProps = ButtonDefaultProps;

export default Button;
