import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'src/components/atoms/Button';
import ConnectedPayrollTile from 'src/components/DUP/molecules/ConnectedPayrollTile/ConnectedPayrollTile';
import { ManualPayrollTile } from 'src/components/DUP/molecules/ManualPayrollTile/ManualPayrollTile';
import { HelpSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import {
  ButtonsContainer,
  Container
} from 'src/components/DUP/molecules/WizardProofOfIncome/styles';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { StyledButton } from 'src/pages/DUPPage/styles';

export const WizardConnectedPayrollSection = (props: WizardProofOfIncomeProps) => {
  const { goBack, onFinished, ...manualPayrollProps } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <ConnectedPayrollTile />
      <ManualPayrollTile {...manualPayrollProps} />
      <HelpSection />
      <ButtonsContainer justify="space-between">
        <StyledButton
          name="back"
          onClick={() => {
            goBack();
          }}
          variant={ButtonVariant.outline}
        >
          {t('buttonLabel.back')}
        </StyledButton>
        <StyledButton name="next" onClick={onFinished} variant={ButtonVariant.contained}>
          {t('buttonLabel.next')}
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};

export default WizardConnectedPayrollSection;
