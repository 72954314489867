import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components/macro';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ModalFooter = styled(Row)`
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 2rem;

  button {
    flex: 1;
  }
`;
