import { HideOnMobileCSS } from 'src/components/DUP/atoms/HideInMobile';
import { HideOnDesktopCSS } from 'src/components/DUP/atoms/HideOnDesktop';
import styled from 'styled-components';

export const FooterRow = styled.div<{ hideOn: 'mobile' | 'desktop' }>`
  width: 100%;
  display: flex;

  ${({ hideOn }) => (hideOn === 'mobile' ? HideOnMobileCSS : HideOnDesktopCSS)}
  ${({ hideOn }) =>
    hideOn === 'mobile'
      ? `position: fixed; bottom: 1rem; left: 6rem; margin-top: 1rem;`
      : 'margin-top: 4rem;'}
`;
