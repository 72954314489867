import { KeyPrefix, Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useApplication } from 'src/context/ApplicationContext';
import enTranslation from 'src/i18n/en';

export interface StepInformation {
  title: string;
  description: string;
}

type DUPTranslation = (typeof enTranslation)['dup'];

export type Information = DUPTranslation['info']['applicant'];

const useInformation = (): Information => {
  const { t } = useTranslation();
  const {
    type,
    property: { name: propertyName }
  } = useApplication();

  const INFO_KEY_PREFIX: KeyPrefix<Namespace> =
    type === 'unauthenticated_user'
      ? 'dup.info.applicant'
      : type === 'leasing_team'
        ? 'dup.info.leasingTeam'
        : 'dup.info.aoa';

  return {
    emailLabel: t(`${INFO_KEY_PREFIX}.emailLabel`),
    prevSubmission: t(`${INFO_KEY_PREFIX}.prevSubmission`, { propertyName }),
    title: t(`${INFO_KEY_PREFIX}.title`),
    titleForm: t(`${INFO_KEY_PREFIX}.titleForm`),
    tooltipMsg: t(`${INFO_KEY_PREFIX}.tooltipMsg`),
    urlTerms: t(`${INFO_KEY_PREFIX}.urlTerms`),
    urlPrivacy: t(`${INFO_KEY_PREFIX}.urlPrivacy`),

    uploadInstruction: {
      1: t(`${INFO_KEY_PREFIX}.uploadInstruction.1`),
      2: t(`${INFO_KEY_PREFIX}.uploadInstruction.2`),
      3: t(`${INFO_KEY_PREFIX}.uploadInstruction.3`)
    }
  };
};

export default useInformation;
