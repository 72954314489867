import dup from './DUP/en.json';
const TEXTS = {
  language: {
    en: 'Inglés',
    es: 'Español'
  },

  validation: {
    completeThisField: 'Complete this field',
    countCharMax: '{{count}} Characters Maximum',
    shouldBeCountOrMore: 'Should be {{count}} or more',
    shouldBeLessThanCountChar: 'Should be less than {{count}} characters',
    shouldNotContainNumOrSym: 'Should not contain numbers or symbols',
    shouldOnlyBeOneChar: 'Should only be one character',

    phone: {
      shouldBeValid: 'Invalid phone number. Make sure to enter a valid US phone.',
      shouldHave10DigitsMin: 'Phone number should have 10 digits minimum.',
      shouldHave15DigitsMax: 'Phone number should have 15 digits maximum.'
    },

    email: {
      shouldBeValid: 'Invalid email address'
    }
  },

  message: {
    yes: 'Yes',
    no: 'No'
  },

  buttonLabel: {
    back: 'Back',
    cancel: 'Cancel',
    edit: 'Edit',
    next: 'Next',
    skip: 'Skip',
    submit: 'Submit'
  },

  dup
};

export default TEXTS;
