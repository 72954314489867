import styled from 'styled-components';

export const StyledTag = styled.span`
  padding: 6px;
  border-radius: 4px;
  background: ${({ theme }) => theme.redesignColor.snappt[50]};
  color: ${({ theme }) => theme.redesignColor.gray[700]};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: fit-content;
`;

export const HowToLink = styled.a`
  color: ${({ theme }) => theme.redesignColor.gray[500]};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.4rem;
  &:hover {
    color: ${({ theme }) => theme.redesignColor.snappt[500]};
  }
`;
