import { datadogLogs } from '@datadog/browser-logs';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import { useSession } from 'src/context/SessionContext';
import { PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL } from 'src/features/DUP';
import useApi from 'src/hooks/useApi';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useResource } from 'src/hooks/useResource';
import { DupApplicationType, SessionApplication, SessionProperty } from 'src/types/api';

export interface ApplicationContextValue {
  isWizard: boolean;
  type: DupApplicationType;
  application: SessionApplication;
  property: SessionProperty;
  connectedPayrollEnabled: boolean;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}

export const ApplicationContext = createContext<ApplicationContextValue | undefined>(undefined);

export const useApplication = () => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error('useApplication must be used within a ApplicationProvider');
  }
  return context;
};

export const ApplicationProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const {
    sessionToken,
    resetSession,
    applicantDetailId: sessionApplicantDetailId,
    applicantIdentifier,
    applicationType: type
  } = useSession();

  const {
    onGetFeatureFlagValue: onGetNewDUPExperienceFF,
    loading: loadingNewDUPExperienceFF,
    data: isNewDUPExperienceEnabledFF
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.applicantDupExperienceRedesign,
    filter: `propertyId=${propertyId}`
  });

  const {
    onGetFeatureFlagValue: onGetConnectedPayrollFF,
    loading: loadingConnectedPayrollFF,
    data: isConnectedPayrollFFEnabled
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.connectedPayroll
  });

  const {
    data: payrollLinkEnabledResponse,
    makeRequest: getPayrollLinkEnabled,
    loading: featureLoading
  } = useApi<{
    enabled: boolean;
  }>({
    url: '',
    loadsOnMount: false
  });

  const [application, { put, refresh }] = useResource<SessionApplication | { error: string }>(
    `/session/application`,
    { silent: true }
  );
  const [property] = useResource<SessionProperty | { error: string }>(`/session/property`);

  const connectedPayrollEnabled = useMemo(
    () => !!isConnectedPayrollFFEnabled && !!payrollLinkEnabledResponse?.enabled,
    [isConnectedPayrollFFEnabled, payrollLinkEnabledResponse]
  );

  const isWizard = useMemo(() => {
    return !!isNewDUPExperienceEnabledFF && type === DupApplicationType.UNAUTHENTICATED_USER;
  }, [isNewDUPExperienceEnabledFF, type]);

  useEffect(() => {
    if (sessionToken) {
      onGetNewDUPExperienceFF();
      onGetConnectedPayrollFF();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (property && !('error' in property)) {
      getPayrollLinkEnabled(`${PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL}/${property.id}/enabled`);
    }
  }, [getPayrollLinkEnabled, property]);

  useEffect(() => {
    if (
      window.heap &&
      (isConnectedPayrollFFEnabled !== null || payrollLinkEnabledResponse !== null)
    ) {
      window.heap.addUserProperties({ connected_payroll_enabled: connectedPayrollEnabled });
    }
  }, [connectedPayrollEnabled, isConnectedPayrollFFEnabled, payrollLinkEnabledResponse]);

  useEffect(() => {
    if (application) {
      if ('error' in application) {
        datadogLogs.logger.info('Error in application object for session', { application });
        resetSession();
      } else if (
        // an applicant could have applications at multiple properties, so we're just making sure we are showing the correct one
        application?.propertyShortId !== propertyId
      ) {
        datadogLogs.logger.info(
          `Property ID does not match application: 
          From Param: ${propertyId}
          From application: ${application.propertyShortId}`,
          { application }
        );
        resetSession();
      } else if (
        // ensuring that the application is for the correct applicant
        sessionApplicantDetailId &&
        sessionApplicantDetailId !== application.applicantDetailId
      ) {
        datadogLogs.logger.info(
          `Applicant identifier does not match application:
           From URL param: ${applicantIdentifier}
           From application object: ${application.applicantDetailId}`,
          { application }
        );
        resetSession();
      }
    }
  }, [
    sessionToken,
    application,
    propertyId,
    sessionApplicantDetailId,
    applicantIdentifier,
    refresh,
    resetSession
  ]);

  if (
    loadingNewDUPExperienceFF ||
    loadingConnectedPayrollFF ||
    featureLoading ||
    !application ||
    'error' in application ||
    application?.propertyShortId !== propertyId ||
    // Don't bother loading the page if we don't have a session token yet
    Boolean(sessionToken) === false
  ) {
    return <Loader isFixed />;
  }

  const onUpdateApplication = async (updates: Partial<SessionApplication>) => {
    await put(updates);
    return await refresh();
  };

  if ((!!application && 'error' in application) || (!!property && 'error' in property)) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup.submission.error.title')}
          message={t('dup.submission.error.message')}
        />
      </Section>
    );
  }

  if (
    !application ||
    !property ||
    application?.propertyShortId !== propertyId ||
    // Don't bother loading the page if we don't have a session token yet
    Boolean(sessionToken) === false
  ) {
    return <Loader isFixed />;
  }

  return (
    <ApplicationContext.Provider
      value={{
        type,
        onUpdateApplication,
        application,
        property,
        isWizard,
        connectedPayrollEnabled
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
